/* COLOR SCHEME

#000b4d  
#FFFFFF
#696969
#00559a
#000a20
*/

/* ANIMS */
@keyframes float-r {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(.5rem);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes float-l {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-.5rem);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* GLOBAL */
@import url('https://fonts.googleapis.com/css?family=Poppins');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: 'Roboto', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Poppins';
}

* p,
h1,
h2,
h3 {
  cursor: default;
}

.page-content {
  display: flex;
  flex-direction: column;
  padding-inline: 10rem;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.logo-img {
  height: 4rem;
  background-color: transparent;
}

.go-top-btn {
  display: none;
  position: fixed;
  bottom: 5rem;
  right: 2rem;
  z-index: 99;
  font-size: 12px;
  border: none;
  outline: none;
  background-color: #00559a;
  color: #ffffff;
  cursor: pointer;
  padding: 1rem;
  border-radius: 4px;
  transition: .3s ease all;
}

.go-top-btn i {
  background-color: transparent;
}

.go-top-btn:hover {
  background-color: #000b4d;
}

/*creo que esto no hace nada*/
.go-top-btn-hdn {
  display: none;
}

/* SCROLLBAR */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #00559a;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

/* NAVBAR */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10;
  width: 100vw;
  background-color: rgba(0, 10, 32, .8);
  padding-inline: 1rem;
}

.nav-content {
  display: flex;
  align-items: center;
  height: 6rem;
}

.nav-list {
  display: flex;
}

.nav-list,
.nav-list li>a {
  list-style: none;
  text-decoration: none;
  color: white;
}

.nav-list li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  padding-inline: 2rem;
  transition: .3s ease all;
}

.nav-list li:hover {
  background-color: rgba(0, 85, 154, .3);
  transition: .3s ease all;
}

/* FOOTER */

footer {
  display: flex;
  flex-direction: column;
  height: 30rem;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  background-color: #000a20;
}

.footer-top,
.footer-bottom {
  padding-block: 3rem;
}

.footer-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0;
  list-style: none;
  align-items: center;
}

.footer-list li>a,
.footer-bottom>a {
  text-decoration: none;
  color: #ffffff;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.footer-bottom>a>img{
  width: 1rem;
  padding-left: 4rem;
}

.footer-bottom>a>img {
  transition: .3s ease all;
}

.footer-list li>a:hover ~ .footer-bottom>a>img,
.footer-bottom>a:hover>img {
  width: 1.2rem;
}


footer hr {
  width: 11rem;
}
/* LOADER */
.loader {
  flex: 100;
  margin-top: 25%;
  justify-content: center;
  align-items: center;
  color: #000a20;
  padding: 1rem;
}

/* SLIDER */
.slide-img {
  width: 25rem;
  padding: 1rem;
  cursor: pointer;
}

.each-slide-effect>div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.react-slideshow-container {
  width: 80rem;
}

.slide-container{
  display: flex;
  justify-content: center;
}

.react-slideshow-container .default-nav {
  background-color: transparent !important;
  margin: -3rem;
}

.long-title{
  font-size: 1.5rem !important;
  padding-bottom: 1.5rem;
}

/* HOME */

.brand-title {
  font-size: 3rem;
}

.brand-subtitle {
  font-size: 2em;
  margin-top: -3em;
}

.landing {
  display: flex;
  flex-direction: column;
  background-color: #000a20;
  color: #ffffff;
  padding: 5rem;
  justify-content: center;
  align-items: center;
}

.landing h2 {
  text-align: center;
}

.landing-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40vw;
}

.landing-visions {
  display: flex;
  flex-direction: row;
  padding: 5rem;
  gap: 4rem;
  width: 90%;
}

.vision-card-content p {
  text-align: justify;
}

.vision-card-content {
  width: 20rem;
}
.vision-card-content h3 {
  text-align: center;
}

/* RESUMEN */
.summary-container,
.summary-container2 {
  display: flex;
  align-items: center;
  text-align: justify;
  width: 115%;
  justify-content: center;
}

.summary-img,
.summary-img2 {
  width: auto;
  height: 20rem;
  cursor: pointer;
}

.summary-container2 div:first-child {
  margin-right: 5%;
}

.summary-container div:last-child {
  margin-left: 5%;
}

.summary {
  display: inline-block;
  width: 50%;
  box-shadow: 0 0 10px -6px #000000;
  padding: 1rem;
}

.summary label {
  font-size: 2.5rem;
  color: #000a20;
}

.home-cards-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-card-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: 2rem;
}

.summary-edit-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 1rem;
}

.summary-edit-options p {
  font-size: .9rem;
  font-style: italic;
  cursor: pointer;
  transition: .3s ease all;
}

.summary-edit-options p:hover {
  color: #00559a;
  transition: .3s ease all;
}

/* MAP CONFIG */
.map-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 8rem;
  margin-bottom: 10rem;
}

.map-container-title h1 {
  font-size: 3rem;
}

.map-container-content {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.map-format {
  display: flex;
  height: 60rem;
  width: 30rem;
}

.map-loc-description {
  box-shadow: 0 0 10px -6px #000000;
  padding: 1rem;
  width: -webkit-fill-available;
  white-space: break-spaces;
}

.home-map p {
  color: #000a20;
  font-size: 1.1rem;
  text-align: justify;
  white-space: break-spaces;
}

.home-map img {
  width: 100%;
  cursor: pointer;
}

.leaflet-bar a {
  background-color: #ffffff !important;
}

.leaflet-bar a:hover {
  background-color: #00559a !important;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  color: #000a20 !important;
}

.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover {
  color: #ffffff !important;
  transition: .3s ease all;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid #00559a !important;
}

.leaflet-container .leaflet-marker-pane img {
  transition: .3s ease all !important;
}

.leaflet-container .leaflet-marker-pane img:hover {
  width: 90px !important;
  height: 90px !important;
  top: -30px;
  left: -30px;
  transition: .3s ease all !important;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  border: 2px solid #000a20 !important;
  box-shadow: 1px 1px 10px 2px rgba(100, 100, 100, 0.3);
}

/* PORTAFOLIO */
.project-see-more {
  display: flex;
  align-items: center;
  gap: .5rem;
  font-size: .9rem;
  text-decoration: none;
  color: #000a20;
}

.project-see-more>.fa-angle-right {
  font-size: .8rem;
}

.project-see-more:hover {
  color: #00559a;
  transition: .3s ease all;
  text-decoration: none;
}

.fa-angle-right {
  animation: float-r 3s ease-in-out infinite;
}

.project-page-title {
  margin-block: 10rem;
  margin-bottom: 0rem;
}

.project-page-title h1 {
  font-size: 3rem;
  color: #000a20;
  justify-content: flex-start;
}

.project-page-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  text-align: justify;
}

.project-page-img {
  height: 25rem;
  cursor: pointer;
}

.project-page-description {
  box-shadow: 0 0 10px -6px #000000;
  padding: 1rem;
  white-space: break-spaces;
}

.projects-title {
  margin-block: 10rem;
}

.projects-title h1 {
  font-size: 2rem;
  color: #000a20;
  text-align: center;
}

.project-card-format-content {
  width: 40rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.project-card-format-content hr{
  width: 39rem;	
}

.project-img {
  width: 100%;
  cursor: pointer;
}

.project-card-pinned-format-content{
  width: 27rem;
  padding: 1rem;
}

.projects-pinned{
  display: flex;
  padding: 1rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

/* PROYECT CARD CONFIGS */
.project-go-back {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.project-go-back a {
  text-decoration: none;
  color: #000a20;
}

.project-go-back:hover {
  color: #00559a;
  transition: .3s ease all;
  text-decoration: none;
}

.fa-angle-left {
  animation: float-l 3s ease-in-out infinite;
}

.project-card-info-data-container {
  margin-bottom: 10rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.input-forms-project-page {
  border: 2px solid #00559a;
  box-sizing: border-box;
  padding: .5rem;
  display: block;
  width: 100%;
  height: 9rem;
  resize: none;
}

.project-cards-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  text-align: justify;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.project-content {
  box-shadow: 0 0 10px -6px #000000;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.project-content>img {
  width: 30rem;
  padding: 1rem;
  cursor: pointer;
}

.project-card-txt {
  box-shadow: 0 0 10px -6px #000000;
  padding: 1rem;
}

.project-card-img {
  box-shadow: 0 0 10px -6px #000000;
  padding: 1rem;
  width: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.project-card-img>img {
  height: 30rem;
  cursor: pointer;
}

/* CONTACTO */
.contact-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15rem;
  padding-bottom: 10rem;
}

.contact-content {
  display: flex;
  flex-direction: column;
  padding-inline: 2rem;
  padding-block: 1rem;
  border: 2px solid #000a20;
  border-radius: 10px;
  width: 30vw;
}

#number {
  font-size: .9rem;
}

.label-box-container {
  text-align: center;
  font-size: 2rem;
}

.err-msg {
  font-style: italic;
  font-size: .7rem;
}

.body-box-container>form {
  display: flex;
  flex-direction: column;
}

.body-box-container .button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.body-box-container textarea {
  resize: vertical;
  min-height: 100px;
  max-height: 150px;
  box-sizing: border-box;
  padding: 10px;
  border: 2px solid #000b4d;
  border-radius: 4px;
}

.body-box-container textarea::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.body-box-container textarea::-webkit-scrollbar {
  width: .3rem;
}

.body-box-container textarea::-webkit-scrollbar-thumb {
  background-color: #00559a;
}

.body-box-container textarea:not(:focus),
.input-box:not(:focus) {
  color: #000b4d;
}

.body-box-container textarea::placeholder {
  color: #000b4d;
}

.input-box {
  padding: 10px;
  display: block;
}

.input-box:focus::placeholder,
.body-box-container textarea:focus::placeholder {
  color: #ffffff;
  transition: .3s ease all;
}

.label-box {
  padding-block: 10px;
}

.button-container {
  padding-block: 1rem;
}

.button-container button {
  height: 50px;
  width: 100px;
  font-weight: bold;
  background-color: #000a20;
  color: #ffffff;
  transition: .3s ease all;
  cursor: pointer;
  border-radius: 5px;
  border-color: transparent;
  cursor: pointer;
}

.button-container button:hover {
  background-color: #000b4d;
  transition: .3s ease all;
}

.button-container button:active {
  background-color: #00559a;
  transition: .3s ease all;
}

/* fullPicturePopup */
.full-pic-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  height: 100%;
}

.full-pic-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* messageOK popup */
.ok-msg-container {
  background-color: #000a20;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease;
  text-wrap: nowrap;
  overflow: hidden;
}

.ok-msg-container.active {
  transform: scaleX(1);
}

.ok-msg {
  color: #ffffff;
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.ok-msg span {
  cursor: pointer;
  font-size: 20px;
  padding-right: 1rem;
}

/* popupContact */
.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}

.form-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 2px solid #000a20;
  height: fit-content;
  width: 35rem;
}

.form-back-container {
  margin-top: 2rem;
  width: 90%;
  display: flex;
  justify-content: flex-end;
}

.form-back-btn {
  color: #ffffff;
  background-color: #000a20;
  border-color: transparent;
  cursor: pointer;
}

.form-back-btn svg {
  font-size: .8rem;
}

.form-back-btn:hover {
  background-color: #00559a;
  transition: .3s ease all;
}

.form-back-btn:active {
  background-color: #000b4d;
}

.form-popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 80%;
  margin-bottom: 2rem;
}

.form-popup-content label,
.form-popup-content p {
  color: #000a20;
}

.form-options {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: flex-start;
  align-items: baseline;
}

.pinned-proyect{
 font-size: 1rem;
}

.form-options input[type="checkbox"] {
  cursor: pointer;
  padding: 1rem;
}


.switch {
  display: inline-block;
  height: 1rem;
  position: relative;
  width: 2.1rem;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #696969;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 0.6rem;
  width: 0.6rem;
  left: 0.2rem;
  bottom: 0.2rem;
  background-color: #FFFFFF;
  transition: .4s;
}

input:checked + .slider {
  background-color: #00559a;
}
 
input:focus + .slider {
  box-shadow: 0 0 1px #696969;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(1.2rem);
}


.form-popup-full-pic {
  animation: fadeIn 0.3s ease-out;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40rem;
  box-shadow: 1px 1px 10px 2px rgba(100, 100, 100, 0.3);
  border: 2px solid #000a20;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.forms-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.forms-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: 39%;
  color: #ffffff;
  background-color: #000a20;
  border-color: transparent;
  cursor: pointer;
}

.forms-container button:hover {
  background-color: #00559a;
  transition: .3s ease all;
}

.forms-container button:active {
  background-color: #000b4d;
}

.forms-container p {
  text-align: center;
}

.form-btns {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.btn-forms {
  margin-inline: 0 !important;
  width: 5rem;
}

.search-results {
  height: 3.85rem;
  overflow-x: hidden;
  padding: 1rem;
  overflow-y: scroll;
  padding-top: 0;
  margin-top: -6.1rem;
  border: 2px solid #000a20;
}

.search-results>div {
  cursor: pointer;
}

.search-results-input {
  border: 2px solid #000a20;
  box-sizing: border-box;
  padding: .5rem;
  margin-bottom: 5rem;
}

.input-forms {
  border: 2px solid #000a20;
  box-sizing: border-box;
  padding: .5rem;
}

.input-forms-text-area {
  border: 2px solid #000a20;
  box-sizing: border-box;
  padding: .5rem;
  height: 10rem;
  resize: none;
  text-align: justify;
}

.input-forms-text-area-vision {
  border: 2px solid #000a20;
  box-sizing: border-box;
  padding: .5rem;
  height: 10rem;
  resize: none;
  text-align: justify;
}

.input-forms-text-area-vision:not(:focus) {
  color: #000b4d;
}

.input-forms-text-area-vision::placeholder {
  color: #000b4d;
}

.input-forms-text-area-vision:focus {
  background-color: rgba(0, 0, 0, .1);
  transition: .3s ease all;
}

.input-forms-text-area-vision:focus::placeholder {
  color: #ffffff;
  transition: .3s ease all;
}

.input-forms-text-area-vision::-webkit-scrollbar {
  width: 4px;
}

.input-forms-text-area::placeholder {
  color: #000b4d;
}

.input-forms-text-area:focus {
  background-color: rgba(0, 0, 0, .1);
  transition: .3s ease all;
}

.input-forms-text-area:focus::placeholder {
  color: #ffffff;
  transition: .3s ease all;
}

.input-forms-text-area::-webkit-scrollbar {
  width: 4px;
}

.input-forms-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.input-forms-img {
  color: #ffffff;
  display: none;
}

.input-forms-img-container input[type="file"]::file-selector-button {
  color: #000a20;
  background-color: #ffffff;
  cursor: pointer;
  display: none;
}

.input-forms-img-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .8rem;
  padding: .4rem;
  color: #ffffff !important;
  background-color: #000a20;
  border-color: transparent;
  cursor: pointer;
}

.input-forms-img-btn:hover {
  background-color: #00559a;
  transition: .3s ease all;
}

.input-forms-img-btn:active {
  background-color: #000b4d;
}

.input-forms-img-file {
  color: #000a20;
  padding: 1rem;
  width: 15rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.input-forms-err {
  color: #000a20;
  font-size: 10px;
}

.body-box-container textarea:not(:focus),
.input-forms:not(:focus) {
  color: #000b4d;
}

.body-box-container textarea::placeholder {
  color: #000b4d;
}


.input-forms:focus,
.body-box-container textarea:focus {
  background-color: rgba(0, 0, 0, .1);
  transition: .3s ease all;
}

.input-forms:focus::placeholder,
.body-box-container textarea:focus::placeholder {
  color: #ffffff;
  transition: .3s ease all;
}

/* NOT FOUND PAGE */
.not-found-container {
  height: 100vh;
  display: flex;
  gap: 5rem;
  justify-content: center;
  align-items: center;
}

.nf-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nf-left p {
  text-align: center;
}

.nf-left hr {
  width: 100%;
  color: #000a20;
  margin-top: -1rem;
}

.logo-big {
  width: 15rem;
}

.text-logo {
  width: 20rem;
}

/* ADMIN VIEW */
nav div> :where(.admin-access) {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3rem;
  margin-top: 4px;
}

.admin-access {
  display: flex;
  gap: 1rem;
  font-size: .8rem;
  color: #ffffff;
  cursor: pointer;
}

.admin-access h3:hover {
  cursor: pointer;
}

.admin-access span {
  font-size: 1.2rem;
  cursor: pointer;
  transition: .3s ease all;
}

.admin-access:hover>span {
  color: #00559a;
  transition: .3s ease all;
}

.home-info-edit {
  width: fit-content;
  font-size: .9rem;
  font-style: italic;
  cursor: pointer;
}

.home-info-edit:hover {
  color: #00559a;
  transition: .3s ease all;
}

.edit-contact-container {
  display: block;
}

.vision-edit {
  width: fit-content;
  font-size: .9rem;
  font-style: italic;
  cursor: pointer;
}

.vision-edit:hover {
  color: #00559a;
  transition: .3s ease all;
}

.vision-edit-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.vision-edit-project {
  width: fit-content;
  font-size: 0.9rem !important;
  font-style: italic;
  cursor: pointer !important;
}

.vision-edit-project:hover {
  color: #00559a;
  transition: .3s ease all;
}

.edit-project-container {
  display: flex;
  justify-content: center;
}

.edit-project {
  font-size: .9rem;
  font-style: italic;
  cursor: pointer;
  transition: .3s ease all;
}

.edit-project:hover {
  color: #00559a;
  transition: .3s ease all;
}

.edit-project-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: -2rem;
}

.edit-project-options p {
  font-size: .9rem;
  font-style: italic;
  cursor: pointer;
  transition: .3s ease all;
}

.edit-project-options p:hover {
  color: #00559a;
  transition: .3s ease all;
}

.add-more-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
}

.add-more-info-container p {
  font-size: .9rem;
  font-style: italic;
  cursor: pointer;
  transition: .3s ease all;
}

.add-more-info-container p:hover {
  color: #00559a;
  transition: .3s ease all;
}

.contact-info-edit {
  width: fit-content;
  font-size: .8rem;
  font-style: italic;
  cursor: pointer;
}

.contact-info-edit:hover {
  color: #00559a;
  transition: .3s ease all;
}

/* MEDIA QUERYS */
/* 
tamanios incluidos: 
-> mobile: 480px 
-> tabletPortait: 768px
-> tabletLandscape: 1024px 
*/

@media (max-width: 480px) {

  /* MOBILE GENERAL */
  .page-content {
    display: flex;
    flex-direction: column;
    padding-inline: 2rem;
    overflow: hidden;
  }

  /* MOBILE NAVBAR */
  nav .logo-img {
    display: none;
  }

  nav ul {
    padding: 0;
  }

  nav {
    position: fixed;
    z-index: 10;
    width: 100vw;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  .nav-list li {
    padding-inline: 1rem;
    font-size: .9rem;
  }

  /* MOBILE SLIDE */

  .slide-img {
    width: 15rem;
  }  

  .react-slideshow-container {
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    width: 50rem;
  }
  /* MOBILE FOOTER */
  footer {
    height: 20rem;
  }

  .footer-top,
  .footer-bottom {
    padding-block: 1rem;
  }

  .footer-bottom {
    margin-bottom: 1rem;
  }

  .footer-list {
    gap: 1rem;
  }

  /* MOBILE HOME */
  .landing {
    padding: 5rem;
    padding-inline: 3rem;
  }

  .landing-visions {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 1rem;
  }

  .vision-card-content p {
    font-size: .8rem;
  }


 .brand-title {
   font-size: 2rem;
}

.brand-subtitle {
    font-size: 1em;
    margin-top: -3em;
    text-wrap: nowrap;
    padding-left: 1em;
    padding-right: 1em;
   }

  /* MOBILE RESUMEN */

.summary-container {
    flex-direction: column;
    gap: 1rem;
  }

  .summary-container2 {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .summary-img,
  .summary-img2 {
    height: 10.3rem;
  }

  .summary-container2 div:first-child {
    margin-right: 0;
  }

  .summary-container div:last-child {
    margin-left: 0;
  }

  .summary {
    width: 80%;
  }

  .summary label {
    font-size: 1.5rem;
  }

  .summary p {
    font-size: .8rem;
  }

  .home-card-data {
    padding-block: 1rem;
  }

  /* MOBILE MAP CONFIG */
  .map-container {
    padding-inline: 1rem;
    text-align: center;
    margin: 0;
  }

  .map-container h1 {
    font-size: 2rem;
  }

  .map-container-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .map-format {
    height: 18rem;
    width: 18rem;
  }

  .map-loc-description {
    width: 18rem;
  }

  .home-map {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .home-map>.project-card-format-content {
    width: 100%;
  }

  .home-map p {
    font-size: .8rem;
  }

  .leaflet-container .leaflet-marker-pane img:hover {
    width: 45px !important;
    height: 45px !important;
    top: -10px;
    left: -10px;
    transition: .3s ease all !important;
  }

  /* MOBILE PORTAFOLIO */

  .project-see-more {
    align-items: center;
    color: #000a20;
    display: flex;
    font-size: 0.6rem;
    gap: 0.5rem;
    text-decoration: none;
    padding-left: 1rem;
  }

  .project-card-pinned-format-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    width: 13rem;
    align-content: center;
    flex-wrap: wrap;
  }

 .project-card-pinned-format-content hr{
    width: 20rem;
  
  }
  .projects-title {
    margin-top: 8rem;
    margin-bottom: 2rem;
  }

  .projects-title h1 {
    font-size: 1.5rem;
  }

  .project-page-title {
    margin-top: 8rem;
  }

  .project-page-title h1 {
    font-size: 1.5rem;
  }

  .project-page-content {
    display: flex;
    flex-direction: column;
  }

  .project-page-img {
    height: 11.5rem;
  }

  .project-page-description {
    font-size: .8rem;
  }
  
  .project-img {
    cursor: pointer;
    width: 20rem;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .projects-pinned {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
  }

  /* MOBILE CARD CONFIGS */
  .project-card-info-data-container {
    margin-bottom: 5rem;
  }

  .project-go-back {
    font-size: .9rem;
  }

  .fa-angle-left {
    font-size: .8rem;
  }

  .project-card-txt {
    font-size: .8rem;
  }

  .project-card-img>img {
    width: 100%;
    height: 100%;
  }

  .project-content {
    display: flex;
    flex-direction: column;
  }

  .project-content p {
    font-size: .8rem;
  }

  .project-content img {
    width: 20rem;
    padding: 0;
  }
  
  .project-card-format-content hr{
   width: 20rem;	
  }

  /* MOBILE fullPicturePopup */
  .form-popup-full-pic {
    height: 100%;
  }

  /* MOBILE popupContact */
  .form-popup {
    width: 20rem;
  }

  /* MOBILE CONTACTO */
  .contact-box {
    padding-top: 8rem;
    padding-bottom: 2rem;
  }

  .contact-content {
    width: 70vw;
  }

  .label-box-container {
    font-size: 1.5rem;
  }

  /* NOT FOUND PAGE */
  .nf-right {
    display: none;
  }

  .text-logo {
    width: 15rem;
  }

  .nf-left p {
    font-size: .9rem;
  }

  /* MOBILE ADMIN VIEW */
  nav div> :where(.admin-access) {
    margin: 0;
    height: 6rem;
    padding-inline: .5rem;
  }

  .admin-access {
    gap: .5rem;
    font-size: .7rem;
    margin-top: .05rem;
  }

  .admin-access span {
    font-size: .9rem;
  }

  .vision-edit-project{
    font-size: 0.5rem !important;
  }

}

@media (min-width: 481px) and (max-width: 768px) {

  /* TABLET GENERAL */
  .page-content {
    display: flex;
    flex-direction: column;
    padding-inline: 2rem;
    overflow: hidden;
  }

  /* TABLET FOOTER */
  footer {
    height: 20rem;
  }

  .footer-top,
  .footer-bottom {
    padding-block: 1rem;
  }

  .footer-bottom {
    margin-bottom: 1rem;
  }

  .footer-list {
    gap: 1rem;
  }

  /* TABLET HOME */
  .landing {
    padding: 5rem;
    padding-inline: 4rem;
  }

.brand-subtitle {
    font-size: 1em;
    text-wrap: nowrap;
}

  .landing-visions {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 1rem;
  }

  /* TABLET RESUMEN */
  .summary-container {
    flex-direction: column;
    gap: 1rem;
  }

  .summary-container2 {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .summary-img,
  .summary-img2 {
    height: 19rem;
  }

  .summary-container2 div:first-child {
    margin-right: 0;
  }

  .summary-container div:last-child {
    margin-left: 0;
  }

  .summary {
    width: 87%;
  }

  .summary label {
    font-size: 2rem;
  }

  .home-card-data {
    padding-block: 1rem;
  }

  /* TABLET MAP CONFIG */
  .map-container {
    padding-inline: 1rem;
    text-align: center;
    margin: 0;
  }

  .map-container h1 {
    font-size: 2rem;
  }

  .map-container-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .map-format {
    height: 18rem;
    width: 34rem;
  }

  .home-map {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
  }

  .home-map>.project-card-format-content {
    width: 100%;
  }

  .map-loc-description {
    width: 25rem;
  }

  /* TABLET PORTAFOLIO */
  .projects-title {
    margin-top: 8rem;
    margin-bottom: 2rem;
  }

  .projects-title h1 {
    font-size: 2rem;
  }

  .project-card-format-content {
    width: 90%;
  }

  .project-page-title {
    margin-top: 8rem;
  }

  .project-page-title h1 {
    font-size: 2rem;
  }

  .project-page-content {
    display: flex;
    flex-direction: column;
  }

  .project-page-img {
    height: 24.8rem;
  }

  .projects-pinned {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
  }

  .project-img {
    width: 27rem;
  }

  .project-see-more {
    padding-left: 1.5rem;
  }

  /* TABLET CARD CONFIGS */
  .project-card-info-data-container {
    margin-bottom: 5rem;
  }

  .project-card-format-content hr{
   width: 20rem;	
  }

  /* TABLET fullPicturePopup */
  .form-popup-full-pic {
    height: 100%;
  }

  /* TABLET CONTACTO */
  .contact-box {
    padding-top: 8rem;
    padding-bottom: 2rem;
  }

  .contact-content {
    width: 70vw;
  }

  .label-box-container {
    font-size: 1.5rem;
  }

  /* NOT FOUND PAGE */
  .nf-right {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {

  /* LANDSCAPE GENERAL */
  .page-content {
    display: flex;
    flex-direction: column;
    padding-inline: 4rem;
    overflow: hidden;
  }

  /* LANDSCAPE FOOTER */
  footer {
    height: 20rem;
  }

  .footer-top,
  .footer-bottom {
    padding-block: 1rem;
  }

  .footer-bottom {
    margin-bottom: 1rem;
  }

  .footer-list {
    gap: 1rem;
  }

  /* LANDSCAPE HOME */
  .landing {
    padding: 5rem;
    padding-inline: 6rem;
  }

  .brand-subtitle {
    font-size: 1em;
}

  .landing-visions {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 1rem;
  }

  /* LANDSCAPE RESUMEN */

  .summary-container {
    flex-direction: column;
    gap: 1rem;
  }

  .summary-container2 {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .summary-img,
  .summary-img2 {
    height: 22rem;
  }

  .summary-container2 div:first-child {
    margin-right: 0;
  }

  .summary-container div:last-child {
    margin-left: 0;
  }

  .summary {
    width: 87%;
  }

  .summary label {
    font-size: 2rem;
  }

  .home-card-data {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  /* LANDSCAPE MAP CONFIG */
  .map-container {
    padding-inline: 1rem;
    text-align: center;
    margin: 0;
  }

  .map-container h1 {
    font-size: 2rem;
  }

  .map-container-title h1 {
    font-size: 2.5rem;
  }

  .map-container-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 97%;
    padding-block: 2rem;
  }

  .map-format {
    height: 23rem;
    width: 39rem;
  }

  .home-map {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
  }

  .home-map>.project-card-format-content {
    width: 100%;
  }

  /* LANDSCAPE PORTAFOLIO */
  .projects-title {
    margin-top: 11rem;
    margin-bottom: 4rem;
  }

  .projects-title h1 {
    font-size: 3rem;
  }

  .project-card-format-content {
    width: 90%;
  }

  .project-card-format-content h1 {
    font-size: 2rem;
  }

  .project-page-title {
    margin-top: 8rem;
    width: 100%;
  }

  .project-page-title h1 {
    font-size: 2rem;
  }

  .project-page-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .project-page-img {
    width: 100%;
  }

  /* LANDSCAPE CARD CONFIGS */
  .project-card-info-data-container {
    margin-bottom: 5rem;
  }

  .project-card-img>img {
    width: 32rem;
    height: fit-content;
  }

  .project-content {
    display: flex;
    flex-direction: column;
  }

  .project-content p {
    font-size: 1rem;
  }

  .project-content img {
    width: 100%;
    padding: 0;
  }

  .project-card-format-content hr{
   width: 20rem;	
  }

  /* LANDSCAPE fullPicturePopup */
  .form-popup-full-pic {
    height: 100%;
  }

  /* LANDSCAPE CONTACTO */
  .contact-box {
    padding-top: 8rem;
    padding-bottom: 2rem;
  }

  .contact-content {
    width: 80vw;
  }

  .label-box-container {
    font-size: 1.5rem;
  }
}
